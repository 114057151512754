import getFetch from "../../libs/getFetch";
import pushTagToDataLayerGA4 from '@bt-react/components/pushTagToDataLayerGA4';

const RemoveWatchLaterCard = async (event, setLoadingState, setClickedBtnId, talkId) => {

  // Parser event id to get just ID.
  const id = event?.target?.id.replace('Remove', '');

  // Remove Try again message if it's there.
  document.getElementById(`item-meta-footer-buttons-` + id).style.display = "none";

  // Turn on Loading Spinner
  setLoadingState(true);

  // Find which card to turn loading spinner on.
  setClickedBtnId(id);

  const watchLaterParams = {
	talkId: talkId,
  };

  const data = await getFetch('/api/talks/deleteWatchLater', watchLaterParams)

  if ( data.status === 'success' ) {

    // Push events to the GTM GA3 for remove watch-later.
    pushTagToDataLayerGA4('tracking_event', {
      'brighttalk-entity': 'My BrightTALK',
      'brighttalk-action': 'Watch later remove',
      'brighttalk-label': ''
    })

    // Push events to the GTM GA4 for remove watch-later.
    pushTagToDataLayerGA4('click_tt', {
      'click_category': 'My BrightTALK',
      'click_action': 'Watch later remove',
      'click_subtype': '',
    })

	  // Find element now by ID and remove it the grid.
    document.getElementById(`TalksColId-` + id).remove();
  } else {
	// error message
	document.getElementById(`item-meta-footer-buttons-` + id).style.display = 'block';
  }

  // Turn off Loading Spinner
  setLoadingState(false);
};

export default RemoveWatchLaterCard;
