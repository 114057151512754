
/**
 * This function titleToSlug, takes a title: string ( Human readable format). Changes it to lowercase and replaces any spaces with "-"
 * e.g Digital Transformation -> digital-transformation
 * @param {string} title
 * @returns
 */
function titleToSlug(title: string) {
	return title.replace(/\s+/g, '-').toLowerCase()
}

export default titleToSlug;
