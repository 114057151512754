const pushTagToDataLayerGA4 = (event_name: string, obj: any) => {

  const pageEvent = {
    event: event_name
  };

  Object.keys(obj).forEach((value) => {
    Object.assign(pageEvent, { [value]: obj[value] })
  })

  let windowToPush = window['dataLayer'] || []

  try {

    if (!process.env.LOCAL_ENV && parent && parent?.['dataLayer']) {

      windowToPush = parent?.['dataLayer']

    } else {

    }

  } catch (e) { }

  windowToPush.push(pageEvent)

}

export default pushTagToDataLayerGA4
