import React, { FC } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  index: number;
};

const TryAgain: FC<Props> = ({ index }) => {
  return (
    <p
      key={`item-meta-footer-buttons` + index}
      className={styles['item-try-again']}
	  id={`item-meta-footer-buttons-` + index}
    >
	  <FontAwesomeIcon className={styles['bt-section-headings-icon']} icon={faTriangleExclamation} />
      Please try again.
    </p>
  );
};

export default TryAgain;
