import React, {FC, useState} from "react";
import { Button, Tooltip } from 'reactstrap';

interface BtTooltipProps {
    timeZoneName: string
    id?: string
}
  
const BtTooltip: FC<BtTooltipProps> = ({ timeZoneName, id }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <Tooltip
                placement='top'
                isOpen={tooltipOpen}
                target={id}
                toggle={toggle} >
            {timeZoneName}
            </Tooltip>
        </>
    )

}

export default BtTooltip
