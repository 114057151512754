import React, { FC } from 'react';
import { Button } from 'reactstrap';
import styles from './index.module.scss'


// Libraries
import titleToSlug from '../../libs/titleToSlug/titleToSlug';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

interface RemoveWatchCardButtonProps {
  index: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  loading: boolean;
  clickedBtnId: number
}

const RemoveWatchCardButton: FC<RemoveWatchCardButtonProps> = ({
  index,
  onClick,
  title,
  loading,
  clickedBtnId
}) => {
  return (
	<>
	<Button
      key={title + index}
      id={title +index}
      data-bdd={`${titleToSlug(title)}-btn`}
      name="temType"
      onClick={onClick}
      className={classNames(
		styles['text-link-secondary'],
		styles['watch-later-remove-button'],
		styles['sml']
	  )}
    >
      {loading && clickedBtnId == index ? <FontAwesomeIcon id={title +index} icon={faSpinner} spin={true} size={'1x'} /> :title}
    </Button>
	</>

  );
};

export default RemoveWatchCardButton;
