import React, { FC } from 'react';
// This component is disable server-rendering to avoid server side error.
import dynamic from 'next/dynamic'

// To dynamically load a component on the client side, you can use the ssr option to disable server-rendering.
// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#with-no-ssr
const PDFPrepareBlob = dynamic(() => import('./PDFPrepareBlob'), {
  ssr: false
})

interface PDFBlobProps {
  webcastId: string;
  fromPage: 'recently-viewed' | 'player-page';
  preAuthenticated?: Boolean;
}

const PDFBlob: FC<PDFBlobProps> = ({ webcastId, fromPage, preAuthenticated }) => {
  return(
    <PDFPrepareBlob webcastId= {webcastId} fromPage={fromPage} preAuthenticated={preAuthenticated}/>
  )
}

export default PDFBlob
