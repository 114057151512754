import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import RemoveWatchCardButton from '../RemoveWatchCardButton/RemoveWatchCardButton';
import RemoveWatchLaterCard from '../RemoveWatchLaterCard/RemoveWatchLaterCard';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import TryAgain from '../TryAgain/TryAgain';

type Props = {
  url: string;
  index: number;
  channel: Channel[];
  id: number;
};

export interface Channel {
  id: number;
  link: Link[];
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const WatchLaterButtons: FC<Props> = ({ url, index, channel, id }) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [clickedBtnId, setClickedBtnId] = useState<number>();

  return (
    <>
      <Button href={url} className={classNames(
          styles['bt-button-small-bright-short'],
          styles['filter-buttons'],
          styles['contentTypeButton'],
        )}>Watch</Button>
      <RemoveWatchCardButton
        index={index}
        title="Remove"
        onClick={(e) => {
          RemoveWatchLaterCard(
            e,
            setLoadingState,
            setClickedBtnId,
            channel['id'] + '-' + id
          );
        }}
        loading={loadingState}
        clickedBtnId={clickedBtnId}
      />
	  <TryAgain index={index} />
    </>
  );
};

export default WatchLaterButtons;
