import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { CardText } from 'reactstrap';

type Props = {
  children: ReactNode;
  id: number;
  index: number;
};

const CardsFooterButtonsContainer: FC<Props> = (props, { id, index }) => {
  return (
    <CardText
      key={`item-meta-footer-buttons` + id + index}
      tag="p"
      className={styles['item-meta']}
    >
      <div className={styles['buttons-position']}>{props.children}</div>
    </CardText>
  );
};

export default CardsFooterButtonsContainer;
