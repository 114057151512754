import React, { FC } from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import brightTalkDateTime from '../../../libs/brightTalkDateTime/brightTalkDateTime'
import GlobalIcon from '../../GlobalIcon/GlobalIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import BtTooltip from '../../BtTooltip/BtTooltip'

interface dateTime {

  fromType: "summit" | "talk" | "series" // to check from where the component being used
  dateTime: Date // dateTime is the start time of talks and summits
  status?: "upcoming" | "live" | "recorded" | "processing"
  duration?: number
  endDate?: Date // endDate is a optional one since it's require only for summits.
  id?: string
  pipeDivider?: boolean
  showTime?: boolean
}


const DateTime: FC<dateTime> = ({ id, fromType, dateTime, status, duration, endDate,showTime }) => {

  let isIconVisible = false;

  // Calling the function to get the formatted date and time based the TimeZone.
  const dateTimeData = brightTalkDateTime( { status, dateTime, fromType, duration, endDate } );

  //Check the talk type and from type to display the Calender Icon.
  if(status === 'upcoming' || fromType === 'summit') {
    isIconVisible = true
  }

  return (
    <>

      <time date-time={dateTime} className="Datetime" data-bdd="webcast-datetime">
        { isIconVisible &&
          <FontAwesomeIcon className={ classnames(styles['cal-icon'], styles['bt-icons'] ) } icon={icon({name: 'calendar-day', style: 'light'})} />
        }

        { dateTimeData.pipeDivider ?
        <span data-bdd="talks-date-time">{ dateTimeData.talkDate } 
          { showTime==true &&
          <><span className={styles['text-divider']}>|</span> { dateTimeData.talkTime }</>
          }
        </span>
        :
        <span data-bdd="talks-date-time">{dateTimeData.dateTime}</span>
        }

        {/* Check the is globe icon needed for the timezone */}
        { dateTimeData.isGlobeIcon &&
          // Globe Icon and tooltip for timezone.
          <span id={id} className={classnames(styles['globe-icon'])} >
            <GlobalIcon timeZoneName={dateTimeData.timeZoneFull} />
            <BtTooltip id={id} timeZoneName={dateTimeData.timeZoneFull}/>
          </span>
        }

      </time>

    </>
  )

}

export default DateTime
