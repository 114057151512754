import React, {FC} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

interface GlobalIconProps {
  timeZoneName: string
}

const GlobalIcon: FC<GlobalIconProps> = ({ timeZoneName }) => {
  const globeIcon = () => {

    // Split the full timezone name.
    const timeZone = timeZoneName.split("/");
    // Return the font awesome icon based on the timezone.
    switch(timeZone[0]) {
      case "Africa":
        return <FontAwesomeIcon icon={icon({name: 'globe-africa', style: 'light'})} />
      case "America":
        return <FontAwesomeIcon icon={icon({name: 'globe-americas', style: 'light'})} />
      case "Asia":
      case "Australia":
        return <FontAwesomeIcon icon={icon({name: 'globe-asia', style: 'light'})} />
      default:
        return <FontAwesomeIcon icon={icon({name: 'globe', style: 'light'})} />
    }

  }

  return (
    <>
      { globeIcon() }
    </>
  )
}

export default GlobalIcon
